body {
  margin: 0;
  padding: 0;
  font-family: Cabin, sans-serif !important;
}
body a{
 color: #1e0253;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto Condensed", sans-serif !important;
}
body .header {
  background: #1e0253;
}
body .dropdown-item {
  font-family: "Roboto Condensed", sans-serif;
}
body .page-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
  font-size: 42px;
  color: #1e0253;
  line-height: 55px;
  font-weight: 600;
  text-transform: uppercase;
}
body .page-header {
  display: flex;
  align-items: center;
  margin: 3rem 0 1.5rem;
  flex-wrap: wrap;
}
body .header-brand-img {
  height: auto;
  width: 210px;
  margin-right: 0rem;
}
body .header-brand {
  margin-right: 0;
}
body .header .container .d-flex {
  align-items: center;
  justify-content: space-between;
}
body .avatar {
  border: 2px solid #fff;
  color: #ffffff;
}
body .header .text-default {
  color: #fff !important;
  font-size: 18px;
}
body .header .text-muted {
  color: #fff !important;
}
body .header .flex-lg-row {
  margin: 0 auto;
  display: table;
  width: 100%;
  text-align: center;
 margin-left: 10em;
}
body .header .nav-item {
  display: inline-block;
  align-items: center;
}
body .header .col-lg-3.ml-auto {
  display: none;
}
body .header .nav-item {
  padding: 0 1rem;
  font-size: 18px;
  color: #fff;
}
body .nav-tabs .nav-link.active {
  border-color: #74bf37;
  color: #74bf37;
  background: transparent;
}
body .nav-tabs .nav-link {
  font-family: Cabin, sans-serif;
  font-weight: 500;
  font-size: 18px;
}
body .nav-tabs .nav-link:hover:not(.disabled) {
  border-color: #74bf37;
  color: #74bf37;
}
body .header .nav .nav-item i {
  display: none;
}
body .footer .col.col-auto.ml-auto {
  display: none;
}
body .footer .col.col-12.col-lg-auto.mt-3.mt-lg-0.text-center {
  width: 100%;
}
body .header {
  border-bottom: 0 !important;
}
body .footer {
  background: #1e0253;
  border-top: 1px solid rgba(0, 40, 100, 0.12);
  font-size: 18px;
  padding: 1.25rem 0;
  color: #ffffff;
}

body .btn-secondary {
  color: #495057;
  background-color: transparent !important;
  border-color: 0 !important;
  box-shadow: none !important;
  border: none;
  font-size: 18px;
  position:relative;
}

body .btn-secondary::before{
  content: '';
  background:  url(./images/left-arrow.png);
  width: 16px;
  height: 16px;
  display: block;
  background-size: 16px;
  position: absolute;
  left: 1px;
  top: 13px;
} 

body .btn-secondary:hover {
  color: #495057 !important;
  background-color: transparent !important;
  border-color: 0 !important;
  box-shadow: none !important;
  border: none !important;
  font-size: 18px !important;
}

.investment-table .wd-4 {
  width: 4%;
}

.investment-table .wd-5 {
  width: 10%;
}
.investment-table .wd-75 {
  width:10%;
}
.investment-table .wd-15 {
  width: 10%;
}
.table thead th,
.text-wrap table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #333;
  font-weight: 600;
}
.investment-cl .progress.progress-sm {
  display: none;
}
.investment-cl .card .mb-4 {
  margin-bottom: 0px !important;
}
.investment-cl .h5 {
  font-size: 28px;
}
.card-header .card-title {
  color: #222;
  font-weight: 700;
  font-size: 28px;
}

.card-title.card-title2{
  font-size: 24px;
  margin-top: 0;
    margin-bottom:15px;
}
body .table thead th,
body .text-wrap table thead th {
  font-size: 18px;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
}
body .table tbody tr td .text-inherit {
  font-size: 18px;
}
body .table th,
body .text-wrap table th,
body .table td,
.text-wrap table td {
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
}
body .card-header {
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
}
body .header .dropdown {
  margin-top: 0px;
}
body .header {
  border-bottom: 1px solid rgb(48 35 74);
}
.list-separated {
  font-size: 18px;
}
.investment-table tbody tr td {
  font-size: 18px;
}
body .page-content {
  margin: 3.5rem 0;
}
body .card {
  box-shadow: none;
  position: relative;
  margin-bottom: 3.5rem;
  border: none;
  width: 100%;
  border-radius: 10px;
}
body .membres-row .card {
  margin-bottom: 0em;
}
.col-login .btn-primary {
  padding: 8px 30px;
  background-color: #74bf38;
  border-color: #74bf38;
  border-radius: 10px;
  width:100%;
}
.col-login .btn-primary:hover {
  color: #fff;
  background-color: #74bf38;
  border-color: #74bf38;
  box-shadow: -1px 6px 15px 5px rgba(0, 0, 0, 0.2);
}

body .btn-primary {
  padding: 5px 25px;
  background-color: #74bf38;
  border-color: #74bf38;
  border-radius: 10px;
  width: auto;
  font-size:18px;
}

body .nominees-row .btn-primary{
  background-color: #1e0253;
  border-color: #1e0253;
}

body .btn-primary:hover {
  color: #fff;
  background-color: #1e0253;
  border-color: #1e0253;
  box-shadow: -1px 6px 15px 5px rgba(0, 0, 0, 0.2);
}

body .btn-primary:not(:disabled):not(.disabled):active,body  .btn-primary:not(:disabled):not(.disabled).active,body  .show > .btn-primary.dropdown-toggle {
  background-color: #1e0253;
  border-color: #1e0253;
  box-shadow:none;
}

body .btn-primary:not(:disabled):not(.disabled):active:focus,body .btn-primary:not(:disabled):not(.disabled).active:focus,body .show > .btn-primary.dropdown-toggle:focus {
  box-shadow:none;
}

body .btn-primary:focus, .btn-primary.focus {
  box-shadow:none;
}

.button-row{
  position:relative;
  display: flex;
    justify-content: space-between;
    align-items: center;
}
body .button-row .btn-primary{
  padding:9px 24px 9px 35px;
  color: #797e83;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
}

body .button-row .btn-primary:active{
  color: #1e0253 !important;
  background: transparent !important;
  border: none !important;
  outline:0 !important;
}

body .button-row .btn-primary:hover{
  text-decoration: none;
  background: transparent !important;
  box-shadow: none;
  color: #1e0253;

}
.button-row .btn-primary::before{
  content: '';
  background:  url(./images/edit.png);
  width: 15px;
  height: 15px;
  display: block;
  background-size: 15px;
  position: absolute;
  left:10px;
  top: 11px;
  z-index: 999;
}
.button-row .btn-secondary{
  color:#797e83;
  font-size: 16px;
    font-weight: 600;
}
.button-row .btn-secondary::before{
  content: '';
  background:  url(./images/delete.png);
  width:15px;
  height:15px;
  display: block;
  background-size:15px;
  position: absolute;
  left: -21px;
  top:4px;
  z-index: 999;
}

.button-row .btn-secondary:hover{
  text-decoration: none;
  color:#797e83 !important;
  font-size: 16px !important;
  font-weight: 600;
}

body .nominees-row .btn-primary{
    padding:5px 24px 3px 35px;
  font-size: 17px;
  position: relative;
  background: transparent;
  border: none;
  color: #1e0253;
  font-weight: 600;
  border: 1px solid #180052;
}

body .nominees-row .btn-primary:active{
  color: #1e0253 !important;
  background: transparent !important;
  border: none !important;
  outline:0 !important;
}

body .nominees-row .btn-primary:hover{
  background: transparent !important;
  box-shadow: none !important;
  color: #1e0253 !important;
}

body .nominees-row .btn-primary.Addbtn::before{
  content: '';
  background:  url(./images/add.png);
  width: 17px;
  height: 17px;
  display: block;
  background-size: 17px;
  position: absolute;
  left: 10px;
  top:11px;
  z-index: 999;
}
body .nominees-row .btn-primary.Editbtn::before{
  content: '';
  background:  url(./images/edit.png);
  width: 17px;
  height: 17px;
  display: block;
  background-size: 17px;
  position: absolute;
  left: 10px;
  top:9px;
  z-index: 999;
}

/*.modal-header .close::after{
  display: inline-block;
  content: '';
  width: 45px;
  height: 45px;
  background-image:  url(./images/cancel.png);
  background-color: #1e0253;
  background-repeat: no-repeat;
  position: absolute;
  top: -24px;
  right: -24px;
  border-radius: 12px;
  background-size: 16px;
  background-position: 14px 14px;
  cursor: pointer;
}*/

.modal-header .close:before{display:none;}
.modal-header .close span{
	background-color: #1e0253;
    background-repeat: no-repeat;
    position: absolute;
    top: -25px;
    right: -24px;
    border-radius: 12px;
    background-size: 16px;
    background-position: 14px 14px;
    cursor: pointer;
    display: inline-block;
    content: '';
    font-size: 36px;
    width: 45px;
    font-weight: 500;
    z-index: 99;
    color: #fff;
    height: 44px;
    line-height: 39px;
}

.modal-header .close {
    float: right;
    font-size: 1.40625rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: inherit;
}

.modal-header .card-title:only-child {
    margin-bottom: 22px;
}

body .modal-header {
    padding: 0;
    border-bottom: none;
}

.col-login .btn {
  font-size: 20px;
  text-transform: uppercase;
}
.col-login .card-title {
  font-size: 54px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 1.2rem;
  color: #1e0253;
  font-family: "Roboto Condensed", sans-serif;
  text-align: left;
}
body .form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 1.1rem;
  text-align: left;
  margin-left: 5px;
}
.bg-color {
  background: #1e0253;
}
.col-login img {
  width: 240px;
  height: auto !important;
}
body .nav-tabs .nav-link {
  border-bottom: none;
}
body .membres-row .card-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: none;
}
body .membres-row .card-body {
  padding: 0rem 1.5rem 2em 1.5em;
}
.box-min .page-single {
  padding: 0rem 0;
}
.box-min .page-single {
  align-items: inherit;
}
.box-min .col-login {
  max-width: 50%;
}
.box-min .col-login {
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.bg-color-gr {
  background-color: rgba(30, 2, 83, 0.1);
}
.col.col-login.col-sm-6.bg-color-gr {
  padding: 0 14em;
}
.col-login .myList {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.col-login .myList li {
  font-size: 32px;
  color: #fff;
  margin-bottom: 50px;
}
.col-login .myList li span {
  font-size: 18px;
  color: #fff;
  display: block;
}
.col.col-login.col-sm-6.bg-color {
  padding: 0 14em;
}
.logo-top {
  margin-bottom: 50px;
}
.col-login .card-body.p-6 {
  padding: 3rem !important;
}
.col-login .form-control {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 0.9rem;
}
body .form-group {
  margin-bottom: 2rem;
}
body .form-control {
  border-radius: 10px;
  font-size: 0.9rem;
}
body .form-control.normal {
  text-transform: initial !important;
}
body .card-title {
  font-size: 42px;
  color: #1e0253;
  line-height: 55px;
  font-weight: 600;
  text-transform: uppercase;
}
.dropdown-menu-right .dropdown-item {
  padding: 0.7rem 1.5rem;
}
.dropdown-menu-right .dropdown-item:hover,
.dropdown-menu-right .dropdown-item:focus {
  color: #fff !important;
  text-decoration: none;
  background-color: #74bf37;
  outline: none;
  cursor: pointer;
}

body .dropdown-item.active .dropdown-icon{
  color: #fff;
}

body .dropdown-item {
  color: #1e0253;
}

body .dropdown-item.active,body .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #74bf37;
}

body .dropdown-icon {
 color: #1e0253;
    font-weight:600;
}
.dropdown-menu-right .dropdown-item:hover i,
.dropdown-menu-right .dropdown-item:focus i {
  color: #fff !important;
}
.dropdown-menu.dropdown-menu-right {
  padding: 0rem 0;
}
.col-login h1 {
  color: #fff;
  font-weight: 400;
  font-size: 42px;
  line-height: 75px;
  margin-top: 235px;
  z-index: 99;
  position: relative;
}
.col-login .dot-img {
  width: 226px;
  height: 134px;
  position: absolute;
  left: -110px;
  opacity: 0.9;
}
.col-login .pettan-img {
  background-size: 150px;
  width: 90px;
  height: 77px;
  position: absolute;
  right: 0px;
  bottom: 0;
  opacity: 0.5;
  background-image: orangered;
}
.right-box .inner-row {
  width: 100%;
}
.col-login.right-box .card-body.p-6 {
  padding: 10rem !important;
}
.right-box .form-group {
  margin-bottom:1.5rem;
}
.invalid-feedback {
  text-align: left;
}
.col-login .form-footer {
  margin-top:2rem;
}
.col-login.right-box p {
  text-align: left;
  margin-bottom:2em;
  font-family: Cabin, sans-serif;
  font-size: 16px;
}
.col-login.right-box .card {
  background-color: transparent;
}
.form-footer {
  text-align: left;
}

.form-footer a {
  color:#1e0253;
  font-size: 18px;
}
.form-footer a:hover {
  color:#1e0253;
  text-decoration:underline;
}
.register-right a {
  color:#1e0253;
  font-size: 18px;

}
.register-right a:hover {
  color:#1e0253;
  text-decoration: underline;
}

.form-control{
  height: 40px;
}
.col-login.right-box.register-right .card-body.p-6 {
  padding: 2rem 10rem !important;
}
.register-right .text-center.mb-6 {
  margin-bottom: 0 !important;
}
body .register-right .card {
  margin-bottom: 0;
}
.col-login .card {
  margin-bottom: 0;
}
.col-login .text-center.mb-6 {
  margin-bottom: 0 !important;
}
.sc-bwzfXH {
  position: absolute;
  top: -9px;
  right: -23px;
  padding: 1px 7px;
  background: black;
  border-radius: 20px;
  z-index: 999999;
}
.PhoneNumberGroup {
  position: relative;
  margin-bottom: 2rem !important;
}
.PhoneNumberGroup select {
  width: 30%;
  display: inline-block;
  margin-right: 10px;
}
.PhoneNumberGroup select.form-control:not([size]):not([multiple]) {
  height: 2.8rem;
}
.PhoneNumberGroup input[name="PhoneNumber"] {
  width: 68%;
  display: inline-block;
}
.PhoneNumberGroup > .invalid-feedback {
  position: absolute;
}
.PhoneNumberGroup > .invalid-feedback ~ .invalid-feedback {
  margin-left: 32%;
}
.investment-cl .h5 {
  color: #222;
  font-weight: 700;
}
body .display-4 {
  font-size: 2.5rem;
}
body .form-control:focus {
  border-color: rgba(0, 40, 100, 0.12);
  box-shadow: none;
}
body .card-profile .social-links {
  display: none;
}
body .card-footer {
  border-top: 0;
}
body .card-footer.mt-25 {
  margin-top: -25px;
  margin-bottom: 10px;
}

.text-danger.suppproted-format{
text-align: center;
  color: #000 !important;
}

.upload-row{
  position:relative;
}

.top-mt25{
  margin-top:20px;
}

.small-title{
  font-size: 20px;
    color: #1e0253;
    line-height: 55px;
    font-weight: 600;
}

.documents-row .form-group {
  margin-bottom: 0;
}

.nominees-row{
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.comment-box{
  resize: none;
  height: 100px;
}

.upload-row .dropzone {
  width: 50%;
  border: 1px solid #e0e5ec;
  border-radius: 10px;
  padding: 10px 15px;
  height: 150px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  margin:0 auto;
}

.upload-row .dropzone::after{
  content: '';
  background:  url(./images/upload-file.png);
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 999;
  background-size: 60px;
  margin: 0 auto;
}

.upload-row .dropzone p{
  text-align: center;
  width: 100%;
  margin-bottom: 0;
  margin-top: 50px;
}

.link-row{
  text-align: right;
}

.link-row a{
  color:#797e83;
      font-weight: 600;
	      font-size: 16px;
  text-decoration: none;
  padding: 9px 24px 9px 35px;
  position:relative;
}

.link-row a.cancel{
  color: #999;
}

.link-row a.cancel:hover{
  color: #333;
}

.link-row a:hover{
  color:#1e0253;
  text-decoration: none;
  font-weight: 700;

}

body .breadcum {
  margin-bottom: 1.5rem;
  margin-top: 1em;
}

body .breadcum a{
  color: #1e0253;
}

.investment-table.my-family-table .wd-15 {
  width: 3%;
}

.hr-line{
margin-top:1rem;
margin-bottom:1rem;
height: 30px;
background: #f5f7fb;
border: none;
width: 120%;
left: -10%;
position: relative;
}

.form-footer a.register-link{
  display: block;
  text-align: right;
  /* color: #74bf37; */
  text-transform: capitalize;
}

.form-footer a.login-link{
  display: block;
  text-align: right;
  /* color: #74bf37; */
}

.form-footer a.cancel-link{
  color: #999 !important;
  font-weight: 700;
  text-decoration: none;
  font-size: 16px;
  cursor:pointer;
}

.form-footer a.cancel-link:hover{
  color: #1e0253 !important; 
}

select.form-control:not([size]):not([multiple]) {
  height: 40px !important;
}

.form-footer .mb25{
  margin-bottom: 15px;
  display: inline-block;
}

.link-box{
  display: flex;
  margin-top:15px;
}

.link-box span{
  font-size: 18px;
  margin: 0 5px 0 0;
}

.register-right .PhoneNumberGroup select{
  margin-top: -7px;
}

.profile-top-row{
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 30px 15px;
}

.profile-top-row .card-title{
  margin-bottom:0px;;
}

.profile-top-row .btn-primary{
  color: #1e0253;
  background: transparent !important;
  font-weight: 600;
  font-size: 17px;
  border: none;
  padding: 0 0;
  position: relative;
}


.profile-top-row .btn-primary:hover{
  color: #1e0253;
  background: transparent !important;
  font-weight: 600;
  font-size: 17px;
  border: none;
  padding: 0 0;
  box-shadow: none;
}

.profile-top-row .btn-primary:active{
  color: #1e0253 !important;
}

.profile-top-row .btn-primary::before{
  content: '';
  background:  url(./images/rotation-lock.png);
  width: 22px;
  height: 22px;
  display: block;
  background-size: 22px;
  position: absolute;
  left: -32px;
  top: 5px;
  z-index: 999;
}

.header .dropdown {
  position: relative;
  z-index: 999;
}

.header .dropdown-menu-arrow.dropdown-menu-right:before,.header .dropdown-menu-arrow.dropdown-menu-right:after {
  left: auto;
  right: 101px;
}

.right-select-row{
  display: flex;
  justify-content: flex-end;
      margin-bottom:15px;
}

.right-select-row .form-group{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-bottom: 0;
}

.right-select-row .form-group label{
  margin-right: 25px;
}

.view-row{
  justify-content: flex-end;
}

.shared-documents-table .investment-table .wd-75 {
  width: 15%;
}

.shared-documents-table .investment-table .wd-75 {
  width: 15%;
}

.shared-documents-table .investment-table .wd-15 {
  width: 15%;
}

.shared-documents-table .investment-table .wd-10 {
  width: 10%;
}

.button-row .btn-primary{
  position: relative;
}

.button-row.view-row .btn-primary::before{
  left: 40px;
  background:url(./images/view.png);
  width: 22px;
  height: 22px;
  display: block;
  background-size: 22px;
  position: absolute;
  left: 6px;
  top: 10px;
  z-index: 999;
}

.button-row .btn-primary:focus{
  outline: 0;
}

body .card-profile .card-header{
	 background: #1e0253;
	     position: relative;
    overflow:hidden;
	height:6rem;
}

.card-profile .card-header:before{
  content: '';
  background:  url(./images/Vector-Smart-Object-W.png);
    width: 150px;
    height: 150px;
    display: block;
    position: absolute;
    top: -20px;
    z-index: 99;
    right: -72px;
    background-size: 150px;	
}

.card.link-row ul {
	padding:0px;
	margin:0px;
}

.card.link-row ul li {
    list-style: none;
    text-align: left;
}

.card.link-row ul li a:hover{
	background: #1e0253;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
}
.card.link-row ul li.active a{
	background: #1e0253;
    color: #fff;
    border-radius: 10px 10px 0px 0px;	
}

.card.link-row ul li a{
	display: block;
	padding:1.2em 2em;
}

.card.link-row ul li:hover a{
	color:#fff;
}

.card.link-row ul li:last-child a{
    border-radius: 0px 0px 10px 10px;	
}

.card.link-row ul li a {
    font-size: 18px;
}

.resend-brn{
  border-radius: 10px;
  font-weight: 600;
  padding: 6px 10px;
  text-decoration: none;
}
.resend-brn:hover{
  color: #1e0253;
  border: 1px solid #1e0253;
  text-decoration: none;
}

label.pending{font-size: 17px;position:relative;}
label.pending:before{
	  background:  url(./images/to-do-list.png);
     width: 20px;
    height: 20px;
    content: '';
    display: block;
    position: absolute;
    background-size: 20px;
    left: -24px;
    top: 0px;
}
label.pending.text-success:before{
  background:  url(./images/success.png);
    width: 22px;
    height: 22px;
    content: '';
    background-size: 22px;
    left: -32px;
    top: 0px;
}

label.pending.text-success{
	margin-left: 15px;
}

.white-box{background:#fff;border-radius:3px;padding:25px 25px;height: 100%;}
.white-box .card-title {color: #222;font-weight: 700;font-size: 28px;}
.subscription-top-text{display: flex;justify-content: space-between;align-items: center;}
.subscription-top-text h6{font-size:20px;}
.subscription-cn-text{padding:25px 0 25px 0;}
.subscription-cn-text span{font-size:16px;font-weight: 600;}
.btn-box{display: flex;justify-content: space-between;align-items: center;}
.btn-box a{color: #1e0253;font-weight: 600;}
.btn-box a:hover{color:#74bf38;}
.payment-top-text h6{font-size:20px;}
.payment-cn-text input{border:none;font-size: 24px;letter-spacing: 2px;}
.mt-55{margin-top:55px;}
body .white-box.invoices .table thead th, body .text-wrap .white-box.invoices table thead th {font-size: 16px;}
.download-img{position:relative;}
.download-img:after{background:  url(./images/download.png);width: 24px;height: 24px;content: '';display: block;position: absolute;right: 0;top: -25px;text-align: center;vertical-align: middle;cursor: pointer;}
.white-box.invoices form span{font-size: 18px;font-weight: 600;}
.basic-paln-taxt h6{font-size:20px;}
.basic-cn-taxt{margin-top:20px;}
.basic-cn-taxt span {font-size: 16px;display: block;margin: 5px 0 0 0;}
.basic-cn-taxt span b{font-weight:700;margin: 0 10px 0 0;}
.basic-bt-taxt{margin-top:20px;margin-bottom: 20px;}
.choose-plan ul{padding:0px;}
.choose-plan li{list-style:none;    margin: 0 0 5px 27px;position:relative;}
.choose-plan li:before{  background:  url(./images/check.png);  width: 16px;height: 16px;content: '';display: block; position: absolute;left: -25px;right: 0;top: 4px;
 background-size: 16px;vertical-align: middle;}  
.choose-plan{margin-top: 20px;}	
.white-box.invoices table { margin-bottom: 3rem;}
.white-box.invoices form .wd-25{width: 25%;}
.white-box.invoices form .wd-35{width: 35%;}
.white-box.invoices form .wd-22{width: 22%;}

.dashboard-counts-link-count.text-primary{color:#1e0253 !important;}
.dashboard-counts-link-count.text-primary:hover{color:#1e0253 !important;}
.investment-cl a.mt-3.d-block{color:#1e0253;font-size: 17px;font-weight: 600;display:inline-block !important;position: relative;text-decoration:none;}
.investment-cl a.mt-3.d-block::before{content: '';background:  url(./images/add.png);width: 17px;height: 17px;display: block; background-size: 17px;position: absolute;left: -23px;
top: 4px;z-index: 999;}
[data-tip]:before {content: '';background:  url(./images/tooltip-icons.png);width:28px;height:28px;display: block; background-size:28px;position: absolute;
top:-1px;z-index: 999;cursor:pointer;right:0px;}
body [data-tip] .form-label{margin-left:35px;}
body  .joint-ownership .form-label{    margin-bottom:11px;}
.upload-row .dropzone{cursor: grab;}

body .no-investment-btn{color:#fff; background-color: #1e0253; border-color: #1e0253;  font-size: 18px !important;  border-radius: 10px;  width:auto;  padding: 5px 25px;}

.link-row a:before{content: '';background:  url(./images/save.png);width: 13px;height: 16px;display: block;background-size: 13px;
position: absolute;left:12px;top:13px;z-index: 999;cursor: pointer;}
.link-row a.cancel:before{content: '';background:  url(./images/cancel-icons.png);width: 13px;height: 13px;display: block;background-size: 13px;
position: absolute;left:14px;top:14px;z-index: 999;cursor: pointer;}
.link-row a.cancel {position: relative;}
.link-row a.cancel:hover{color:#dd5561;}
.my-family-table .wd-25{width:32%}
.my-family-table .wd-20{width:32%}
.my-family-table .wd-32{width:24%}
.my-family-table .wd-17{width:17%}
.no-data-available-image:before{content: '';background:  url(./images/no-shared.png)  no-repeat;width:65px;height:75px;display: block;background-size:65px;z-index: 999;cursor: pointer;margin:0 auto;}
.no-data-available-image span{color: #1e0253 !important;font-size: 20px !important;text-transform: capitalize !important;}
.no-data-available-image{display: revert;align-items: center; margin: 0 auto; width: 100%; justify-content: center;}
body .form-label.left-15{position:relative;display: block;}
body .custom-control-label::after{top: 0.2rem;}
body .custom-control-label::before{top: 0.2rem;}

body .font-20 .custom-control-label::after{top: 0.5rem;}
body .font-20 .custom-control-label::before{top: 0.5rem;}
.button-row2 a{padding: 9px 24px 9px 35px;position: relative;}
.button-row.button-row2 .btn-secondary::before{left: 10px;top: 13px;}
.custom-checkbox.font-20.mt-7{font-size: 17px;}
body .custom-checkbox.font-20.mt-7 .custom-control-label::before {top: 0.3rem;}
.investment-table tbody tr td a{color: #1e0253;text-decoration:underline;}
.investment-table tbody tr td a:hover{color: #1e0253;text-decoration:none;}
.shared-documents-table .react-bootstrap-table a{color: #1e0253;text-decoration:underline;}
.shared-documents-table .react-bootstrap-table a:hover{color: #1e0253;text-decoration:none;}
.shared-documents-table table tbody tr td{font-size: 18px;vertical-align: middle;}
.react-bootstrap-table-pagination .dropdown-menu.show{margin-left:2%;}
.react-bootstrap-table-pagination .dropdown-menu li a{color: #1e0253;text-decoration:none;}
.react-bootstrap-table-pagination .page-item.active .page-link{background-color: transparent;border-color: inherit;    border: none;}
.react-bootstrap-table-pagination .page-item.active .page-link{color: #392168;font-weight: 500;}
body .react-bootstrap-table-page-btns-ul::before{content: " ";border-left: 3px solid #1e0253 !important;border-bottom: 3px solid #1e0253 !important;width: 15px;height: 15px;transform: rotate(45deg);cursor: pointer;display: block;
vertical-align: middle;margin-top: 10px;margin: 11px 20px 0 0;padding:inherit;background:transparent;border: none;border-radius:inherit;display:none;}
body .react-bootstrap-table-page-btns-ul::after{content: " "; border-right: 3px solid #1e0253 !important;border-top: 3px solid #1e0253 !important;
width: 15px;height: 15px; transform: rotate(45deg); cursor: pointer;display: block;vertical-align: middle;margin-top: 10px;margin: 11px 0px 0 20px;padding:inherit;background:transparent;border: none;border-radius:inherit;display:none;}
.react-bootstrap-table-pagination .page-link:focus{box-shadow:none;}
body .page-link{border: none;}
body .page-link:hover{background-color: transparent;}
body .pagination{margin-bottom:0px;}

.stripeform {
  width: 100%;
  display: block;
}
.loadinggif {
  background: url("https://icon-library.com/images/ajax-loading-icon/ajax-loading-icon-11.jpg")
    no-repeat right center;
  background-size: 25px 25px;
}
.coupontable td {
  padding: 0px !important;
  border-top: 0px solid black !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.mysubscription img {
  width: 70px;
}
.mysubscription .card {margin-bottom:0px;}
/* .myfamily {display: inline !important;}
.myfamily .card-header {display: inline-block;width: 50%;}
.myfamily .card-btn {display: inline-block;width: 50%;} */
.errortoster {display: inline;}
.errortoster h4 {display: inline;}
.errortoster div {margin-top:10px;}
.myfamily .card-header {border-bottom:0px; padding:0 0;}
.breadcum {margin-bottom: 1.5rem;}
.card-title:only-child { margin-bottom: 1.5rem;}
.customebutton{height: 27px;line-height: 0 !important;}
.dropzone {width: 50%;border: 1px solid black;border-radius: 25px;padding: 10px;height: 45px;}
.dynamicinvestment {font-size: 16px !important;font-weight: 100 !important;}
.headingbottomborder { border-bottom: 1px solid #dee2e6;padding-bottom: 1.5em;}
.nominee-text b{color: #333;font-weight: 600;font-size:18px;}
.documents-row .table th{text-transform:capitalize;} 
.filepreview {overflow: scroll;}
.selectmodal > div > div > div {overflow: scroll;}
.blurmypage {filter: blur(2px);pointer-events: none;opacity: 0.7;position:fixed;width:100%;}
canvas {padding-left: 0;padding-right: 0;margin-left: auto;margin-right: auto;display: block;width: 800px;}
.ModalCustomStyle {z-index: 9999 !important;}
.ModalCustomStyle .modal-dialog {max-width:900px;width: 100%;}
.ModalCustomStyle.modal.fade .modal-dialog {transform: translate(0, 0);}
.modal-backdrop {z-index: -1;}
.modal-backdrop.modal {display:block !important;}
.ModalCustomStyle.modal.fade {opacity: 1 !important;}
.ModalCustomStyle.modal {display:block !important;}
.modal-backdrop.fade {opacity: 0.6 !important;}

body .btn.btn-lg.btn-primary{border-color: #74bf38 !important;}  
body .btn.btn-lg.btn-link {color:#1e0253 !important;}
body .modal-header h4{font-size: 42px;color: #1e0253;line-height: 55px;font-weight: 600;text-transform: uppercase;text-align: left;width: 100%;margin: 34px 0 0px 30px;}
.ModalCustomStyle .modal-body .card-body{padding-top: 0em !important;}
.modal-header .close:hover{opacity: inherit;}
body .modal-dialog .card{margin-bottom: 0;}
.family-modal .card-title:only-child { margin-bottom: 1.5rem;}
body .display-4.title-popup{    font-size: 42px;
    color: #1e0253;
    line-height: 55px;
    font-weight: 600;
    text-transform: uppercase;margin: 34px 0 0px 30px !important;display: inline-block;}
.copytoclipboard::before {
	  background:  url(./images/link-text-button.png);
    width: 29px;
    height: 29px;
    content: '';
    background-size: 29px;
    display: inline-block;
    margin-bottom: -8px;
  }
.reactautocomplete input{
  border-radius: 10px !important;
    font-size: 1.2rem;
    height: 40px;
    overflow: visible;
    margin: 0;
    font-family: inherit;
    box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;}
.menu {
  cursor:pointer;
  margin-top: 5px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
  .item {
    cursor:pointer;
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 1.2rem;
}
.paymenttypes {display:inline;width: 35px;margin-top: -5px;}
.paymentcard{display: inline;width: 80%;margin-left: 15px;padding-top: 0px;}
.paymentcardname {margin-left:50px;font-size: 20px;}
.payment-exp {font-size: 20px;}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
}
.font-20 {font-size:20px;}
.dashboard-counts-link {color: black;text-decoration: none;}
.dashboard-counts-link:hover{text-decoration: none;color:black;}
.card-element-custome .CardField-child {
  padding: 0.375rem 0.75rem !important;
  line-height: 1.6 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 40, 100, 0.12) !important;
  border-radius: 3px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.card-element-custome .CardField-expiry {
  transform: translateX(660px) !important;
}
.card-element-custome .CardField-cvc {
  transform: translateX(670px) !important;
}
.font-23 {font-size: 23px;}
.dashboard-counts-link-count {color:black;}
.dashboard-counts-link-count::hover {text-decoration: underline;}
.sharedbylable{width: 115px;}
.termsandcondition p {display: block;width:100%;}
.termsandcondition h2 {display: block;width:100%;}
.termsandcondition h3 {display: block;width:100%;}
.w-200px {width: 200px;}
.shareddatatable tr th:first-child,.shared-loading-table tr th:first-child {width: 195px;}
.shareddatatable tr th:nth-child(2),.shared-loading-table tr th:nth-child(2) {width: 230px;}
.shareddatatable tr th:nth-child(3),.shared-loading-table tr th:nth-child(3) {width: 242px;}
.shareddatatable tr th:nth-child(4),.shared-loading-table tr th:nth-child(4) {width: 200px;}
.shareddatatable tr th:nth-child(5),.shared-loading-table tr th:nth-child(5) {width: 170px;}
.react-bs-table-sizePerPage-dropdown .btn-secondary::before {content: 'Records per page' !important;background: none;position: relative;display: inline;left: 0;top:0;background-size: 16px !important;margin-right: 20px !important;}
.react-bs-table-sizePerPage-dropdown .btn-secondary {padding-left: 20px;}
.dropdown-menu.show:not(.headrmenu) {margin-left: 60%;}
.dropdown-menu.show li a {width: 100%;display: block;}
.react-bootstrap-table-page-btns-ul {float: right;}
.react-bs-table-sizePerPage-dropdown {border: 1px solid #c0bcbc;border-radius: 25px;}
.react-bootstrap-table-page-btns-ul::before {content: '';font-size: 23px;padding: 0px 15px;background: #efeeee;border: 1px solid #c8c8c8;border-radius: 8px 0px 0px 8px;}
.react-bootstrap-table-page-btns-ul::after {content: '';font-size: 23px;padding: 0px 15px;background: #efeeee;border: 1px solid #c8c8c8;border-radius: 0px 8px 8px 0px;}

.investment-table .d-block {font-size: 14px;margin-top: 0 !important;}
.investment-table label.pending:before {margin-left: 0;left: 0;}
.investment-table label:not(.filter-label) {display: block !important;width: 100%; margin-left: 0 !important;padding-left: 30px;}
.investment-table span {display: flex;align-item: center;justify-content: center;}
.investment-nominee-tooltip::before{display: inline-block;position: inherit;}
.investment-table span a.resend-brn {display: block;margin: 0;border: none;background: url(./images/resend-icon.png) no-repeat 0px 5px;color: #797e83;font-size: 14px;padding-left: 30px;background-size: 22px;}
.investment-table span a.copytoclipboard {display: block;margin: 0;border: none;
font-size:14px;background: url(./images/copy-icon.png) no-repeat 0px 8px; color: #797e83;background-size: 20px;padding: 6px 10px 6px 30px;}
.investment-table span a.copytoclipboard:before { display:none;}
.card-table tr td:first-child, .card-table tr th:first-child {padding-left: 0;}
.view-button-image {left: 40px;background:url(./images/view.png);width: 23px;height:23px;display: block;background-size: 23px;position: relative;z-index: 999;}
.currency-dropdown {width: 40% !important;margin-top: -8px;}
.invoicedownload-img{position:relative;}
.invoicedownload-img:after{background:  url(./images/download-icon.png);width: 24px;height: 24px;content: '';display: block;position: absolute;right: 0;top:8px;text-align: center;vertical-align: middle;cursor: pointer;}
form .required{color:#cd201f;}
.card-profile-img {width: 90px !important;height: 90px !important;}
.ProfileUploadModel .modal-body{min-height:500px;}
.crop-container {position: absolute;top: 0;left: 0;right: 0;bottom: 80px;}
.controls {position: absolute;bottom: 0;left: 50%;width: 50%;transform: translateX(-50%);height: 80px;display: flex;align-items: center;}
.slider {padding: 22px 0px;}
.button-row.delete-btn .btn-secondary::before{left: 9px;top: 12px;}
.button-row.delete-btn{text-decoration: underline;}
.button-row.delete-btn:hover{text-decoration:none;}
.setpos{margin: 34px 50px 0px -125px;}

.modal-dialog .btn.setpos{min-width:auto;}
.investment-table.my-family-table span{justify-content:left;}
.react-bootstrap-table-pagination{margin-top: 1em;margin-bottom: 1em;}
.header .dropdown .nav-link.pr-0.leading-none{margin-top: 5px;}
.card-profile label{position:relative;}
.profile-box{width:50px;height: 50px;background: #dddddde0;border-radius: 50%;top: -25px; position: absolute;right: 0;    cursor: pointer;}
.profile-box:after{background:  url(./images/photo-camera.png);width:25px;height:22px;content: '';display: block;margin: 15px 0 0 12px;background-size: 25px;}


.card-profile .remove-profile { width: 18px;height: 18px;border-radius: 50%;top: 33px;position: absolute;right: 0;cursor: pointer;left:110px;margin: 0 auto;background:  url(./images/delete.png);  width: 18px;height: 18px;
content: '';display:none;background-size: 18px;}
.card.link-row ul li a:before{display:none;}
.profile-add .profile-box{top: -35px;left:0px;}
.card.card-profile.profile-add h3{margin-top: 15px;}
.investment-table .warning:after{background:  url(./images/warning.png);width:22px;height:22px;content: '';display: block;background-size:22px;top: 0;left: 0px;}
.investment-table .warning{ position: relative;}

.reactautocomplete .menu .item{font-size: 1rem;} 
.col-login.right-box .form-group.w-25.staysignin{display: flex;width: 100% !important;}
.col-login.right-box .form-group.w-25.staysignin .custom-control-label {margin-left: 0;}
.no-data-available-image button.no-investment-btn{margin-top: 15px;}
.invoice-table .wd-20{width: 20%;}
.invoice-table .invoicedownload-img{margin: 0 auto;display: table;}
.upload-btn .btn-Remove{background-color: #dedede;border-color: #dedede;color: #353535;}

@media (max-width: 1700px) {
  .col.col-login.col-sm-6.bg-color {
    padding: 0 7em;
  }
  .col-login.right-box .card-body.p-6 {
    padding: 7rem !important;
  }
  .col-login.right-box.register-right .card-body.p-6 {
    padding: 2rem 6rem !important;
  }
  .col-login.right-box.register-right p {
    margin-bottom: 2em;
  }
  .right-box.register-right .form-group {
    margin-bottom: 1rem;
  }
  .col-login.register-right .form-footer {

    margin-top: 2rem;
  }
  body .register-right .card {
    margin-bottom: 0;
  }
  .register-right .text-center.mb-6 {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1600px) {
  .col.col-login.col-sm-6.bg-color {
    padding: 0 6em;
  }
  .col-login.right-box .card-body.p-6 {
    padding: 2rem 3rem !important;
  }
  .col-login .card-title {
    font-size:36px;
  }
  .col-login h1 {
    margin-top: 15%;
    font-size: 34px;
    line-height: 50px;
  }
  .col-login.right-box.register-right .card-body.p-6 {
    padding: 2rem 4rem !important;
  }
  
  body .form-label {
    font-size: 1rem;
}

.col-login .form-control {
    font-size: 0.9rem;
}

.col-login .btn {
    font-size: 18px;
}

body .header-brand-img {
    width: 160px;
}

body .page-title {
    font-size: 36px;
    line-height: 44px;
}

.card-body {
    padding: 1rem 1rem;
}

body .display-4 {
    font-size: 2rem;
}

body .card-header {
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
}


.card-header .card-title {
    font-size: 24px;
}

body .table thead th, body .text-wrap table thead th {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

body .table th, body .text-wrap table th, body .table td, .text-wrap table td {
    padding-top: 0.60rem;
    padding-bottom: 0.60rem;
}

.investment-table tbody tr td {
    font-size: 17px;
}

body .membres-row .card-header {
    padding-top: 0.60rem;
    padding-bottom: 0.50rem;
    border: none;
}

body .button-row .btn-primary {
    font-size: 16px;
}

body .modal-class .card-title {
    font-size: 36px;
}

body .card {
    margin-bottom: 3rem;
}
  
}

@media (max-width:1400px) { 
body .investment-cl .font-20{font-size:17px;}
body .page-title{font-size:32px;line-height: 44px;}
.investment-cl .h5 {font-size: 24px;}
body .display-4 {font-size: 1.80rem;}
.investment-cl a.mt-3.d-block {font-size: 16px;}
.investment-cl a.mt-3.d-block::before{width: 15px;height: 15px;background-size: 15px;left: -22px;}
body .table thead th, body .text-wrap table thead th {font-size: 16px;}
.list-separated {font-size: 16px;}
.list-separated-item {padding: 0.50rem 0;}
.investment-table tbody tr td {font-size: 15px;}
body .nav-tabs .nav-link {font-size: 16px;}
body .header .text-default {font-size: 16px;}
body .footer{font-size: 16px;}
body .card-title { font-size: 32px;line-height: 44px;}
body .btn-primary {padding: 5px 18px;font-size: 16px;}
body .react-bootstrap-table-page-btns-ul .page-item{display: flex;align-items: center;justify-content: center;height: 36px;font-size:15px;width: 36px;}
body .form-label {font-size: 14px;}
body .table tbody tr td .text-inherit {font-size: 15px;}
body .no-data-available-image:before{height: 68px;}
body .no-data-available-image {display: grid;}
body .modal-dialog .btn.btn-primary.setpos{margin: 0 12px;}
body .modal-dialog .modal-header{display: block;}
body .modal-dialog .container.modal-popup-dt .card-body{padding: 0 0;}
.shared-documents-table table tbody tr td {font-size: 14px;}
body .white-box.invoices .table thead th, body .text-wrap .white-box.invoices table thead th {font-size: 15px;}


}

@media (max-width: 1359px) { 
.col-login .card-title {font-size: 34px;}
.col-login h1 {margin-top: 145px;}
.PhoneNumberGroup select {width: 28%;display: inline-block;margin-right: 10px;}
.col-login.right-box .card-body.p-6{padding: 2rem 1rem !important;}
.col.col-login.col-sm-6.bg-color {padding: 0 0em;}
.col-login h1 {margin-top: 15%;font-size: 28px;line-height: 38px;}
.col-login .btn {font-size: 16px;}
.col-login.right-box.register-right .card-body.p-6 {padding: 2rem 1rem !important;}
body .table thead th, body .text-wrap table thead th {font-size: 14px;}
.investment-table tbody tr td {font-size: 14px;}
body .button-row .btn-primary {font-size: 14px;}
.button-row .btn-secondary {font-size: 14px;}
.button-row .btn-secondary::before{background-size: 14px;width: 14px;height: 14px;left: -17px;}
.view-button-image{width: 18px;height: 18px;background-size: 18px;}
.investment-table .warning:after {width: 18px;height: 18px;background-size: 18px;}
body .table.text-nowrap{white-space: break-spaces !important;}
.investment-table .wd-4 {width: 2%;}
.investment-table .wd-15 {width: 12%;}
body .table thead th, .text-wrap table thead th {vertical-align: middle;}

}

@media (max-width:1299px) {
body .ModalCustomStyle .modal-dialog {max-width: 660px;}
.investment-cl .h5 {font-size: 21px;}
body .display-4 {font-size: 1.50rem;}
}


@media (max-width:1279px) {
.text-danger.suppproted-format {right: 0%;}
body .header .flex-lg-row {margin-left:5em;}


}

@media (max-width:991px) {
.header-toggler{color: #ffffff !important;}	
.header .d-flex.order-lg-2{order: 2;}
.container {max-width: 95% !important;}
													 
body .header .flex-lg-row{width: 100%;text-align: center;background:#1e0253; margin-left:0em;border-top: 1px solid #716190 !important; z-index: 999;position:relative;}
.header .col.col-lg.order-lg-first{position: absolute;width: 100%;left: 0;top:100px;padding: 0;}
body .header-brand-img {width: 140px; max-width: 140px;}
body .header-toggler{right:85px;position:absolute;}
body .header .nav-item { display: block;padding: 0 3rem;}
body .investment-cl .col-sm-4{flex: 0 0 100%;max-width: 100%;}
body .card {margin-bottom: 1.5rem;}
body .roundedstylehead{font-size: 14px;}
body .investment-cl .font-20 {font-size: 15px;text-align: center;}
.header .dropdown-menu-arrow.dropdown-menu-right:before, .header .dropdown-menu-arrow.dropdown-menu-right:after {right: 10px;}
.membres-row .col-md-6{flex: 0 0 100%;max-width:100%;}
.membres-row .col-md-6:first-child {margin-bottom: 25px;}
table {border: 0;}
table caption { font-size: 1.3em;}
table thead {border: none;clip: rect(0 0 0 0);height: 1px;margin: -1px;overflow: hidden;padding: 0; position: absolute;width: 1px;}
table tr {display: block;margin-bottom: 2em;padding: 0 12px;}
table td {border-bottom: 1px solid #ddd; display: block;font-size:14px;text-align: right;}
table td::before {content: attr(data-label);float: left;font-weight: bold;text-transform: uppercase;}
table td:last-child {border-bottom: 0;}
body .page-header {margin: 2rem 0 1.5rem;}
body .page-content {margin: 2rem 0;}
body .card-table tr td:first-child, body .card-table tr th:first-child {padding-left: 0.75rem;}
body .ModalCustomStyle .modal-dialog {max-width: 90%;margin: 50px auto 25px;}
body .button-row {justify-content: flex-end;}
body .card-table.recent-investment-table tr td:last-child,.card-table.recent-investment-table tr th:last-child {padding-right: 1rem;}
body .investment-table tbody tr td[data-label="Attachment"]{padding: 11px 16px 30px 16px;}
body .view-button-image{float: right;left: inherit;}
body .modal-header h4 {font-size: 32px;margin: 20px 10px 0px 10px;}
body .modal-body .container{padding-right: 0;padding-left: 0; max-width:100% !important;}
.form-footer a {font-size: 15px;}
.link-box span {font-size: 15px;}
.register-right a {font-size: 15px;}
.col-login.right-box p {font-size: 15px;}
.InputContainer .InputElement {font-size: 14px !important;}
.my-family-table tbody tr td .form-label.d-inline.pending.text-success{display: flex !important;justify-content: flex-end;}
.my-family-table label.pending:before {margin-left: inherit; left: inherit;right: 0;top: -22px;}
.my-family-table span.justify-content-start{justify-content: flex-end !important;} 
.my-family-table tbody tr td .form-label.d-inline.pending.text-warning{display: flex !important;justify-content: flex-end;}
.shareddatatable .sharedbynameclass {display: flex;justify-content: flex-end;align-items: center;}
.shareddatatable .sharedbynameclass .name {position:inherit; margin-top: inherit;margin-left: inherit;padding: 0 0 0 4px;}
.shareddatatable .sharedbynameclass .roundednamesharing{margin-bottom:0px;}
body .right-select-row{justify-content: left;}
.right-select-row .form-group {justify-content: left;flex-wrap: wrap;width: 100%;}
.profile-form .col.col-sm-4.col-md-4{flex: 0 0 50%;max-width: 50%;}
.profile-form .full-width-input .col.col-sm-6.col-md-6{flex: 0 0 100%;max-width: 100%;margin-bottom: 0;}
.profile-form .full-width-input .col.col-sm-4.col-md-4{flex: 0 0 100%;max-width: 100%;}
.profile-form .full-width-input .col.col-sm-4.col-md-4.pr-0{padding-right: 0.75rem !important;flex: 0 0 50%;max-width: 50%;}
.profile-form .full-width-input-50 .col.col-sm-6.col-md-6{flex: 0 0 50%;max-width:50%;}
body .profile-form .form-group {margin-bottom: 0rem;}
body .profile-form .col{margin-bottom:15px;} 
.mysubscription-pages .col-lg-12{flex: 0 0 100%;max-width: 100%;}
.mysubscription-pages .payment-methoad .col{flex: 0 0 100%;max-width: 100%;}
.mysubscription-pages .payment-cn-text input {font-size: 18px;letter-spacing: 2px;}
.mysubscription-pages .paymentcardname {font-size: 16px;}
.mysubscription-pages .payment-cn-text{margin: 20px 0 20px 0;}
.mysubscription-pages .payment-exp {font-size: 18px;}
.invoice-table .invoicedownload-img {margin: inherit;display: block;}
.add-mb-wd-50 .col-md-4{flex: 0 0 50%;max-width: 50%;}
.add-mb-wd-50 .col-md-8{flex: 0 0 50%;max-width: 50%;}
.col.add-full-wd-80{flex: 0 0 66%;max-width: 66%;}
.col.add-full-wd-40{flex: 0 0 34%;max-width: 34%;}
body .question-mark-icon{top: -9px;width: 26px;height: 26px;background-size: 26px;}
body .add-investment .form-label {line-height: 15px;}
body .joint-ownership .form-label {margin-bottom: 24px;}
body .nominee-text b {font-size: 15px;}
body .nominees-row .btn-primary{padding:2px 24px 2px 35px;}
body .nominees-row .btn-primary.Addbtn::before {width: 15px;height: 15px;display: block; background-size: 15px;left: 14px;top: 10px;}
.upload-row .dropzone{width: 92%;}
.documents-row .small-title{font-size: 16px;}
body .link-row a {font-size: 14px;}
body .link-row a:before{width: 12px;height: 15px;display: block;background-size: 12px;}
.invoice-table tr{margin-bottom: 3em;}
body .upload-btn .btn-primary {padding: 5px 18px;font-size: 14px;margin-right: 0.5rem !important;}

}

@media (max-width:767px) {
	body .page-single{display: block;}
	.box-min .col-login{max-width:100%;}
	.col-login img {width: 175px;margin-top: 15px;}
	.col-login h1 {margin-top:8%;font-size: 22px;line-height: 28px;}
	.col-login .dot-img {width: 130px;height: 77px;position: absolute;left: -60px; opacity: 0.9;background-size: 130px;bottom:-15px;}
	.col-login .pettan-img{background-size: 113px;idth: 90px;}
	.col-login .card-title {font-size: 26px;}
	.register-right a {font-size: 16px;}
	.col-login .btn {font-size: 15px;}
	.form-footer a {font-size: 16px;}
	.row.row-cards{display:block;}
	body .page-title {font-size: 28px;line-height: 34px;}
	body .header-toggler {right: 70px;}
	.investment-table tbody tr td {font-size: 14px;}
	.register-right .inner-row form .row{display: block;}
	.register-right .inner-row form .row .col-sm-6,	.register-right .inner-row form .row .col-sm-4{margin-bottom: 1em;}
	.col-login.right-box p {font-size: 15px;}
	.register-right a {font-size: 15px;}
	.link-box span {font-size: 15px;}
	body .modal-header h4{font-size: 25px;margin: 34px 5px 0px 5px;line-height:32px;}
	.modal-dialog .container { max-width: 100% !important;padding-right: 0rem;padding-left: 0rem;}
	.modal-header .close span{top: -28px;font-size: 32px;right: -12px;}
	.modal-body ul{padding: 0 0 0 18px;}
	body .modal-header {padding: 0 10px;}
	.setpos {margin: 12px 50px 10px 0px;}
	body .modal-header{display: block;}
	.modal-body .card-body {padding: 1.5rem 0rem;}
	.nominee-text b {font-size: 16px;}
	.modal-dialog .row{display: block;}
	body .modal-dialog .row .form-group {margin-bottom: 1.50rem;}
	.roundedname{font-size: 0.80em;}
	body .card-title {font-size: 25px;line-height: 40px;}
	.list-unstyled .col.col-auto.pt-3{    padding-right: 0rem;}
	.list-unstyled .col{    padding-right: 0rem;}
	body .btn-secondary {font-size: 15px;}
	table tr {padding: 0 0px;}
	.react-bootstrap-table-page-btns-ul {float: left;margin-top: 15px;}
	body .modal-body .container {padding-right: 15px;padding-left: 15px;}
	.no-data-available-image span {font-size: 16px !important;}
	.no-data-available-image span {font-size: 16px !important;}
	body .no-investment-btn {font-size: 15px !important;}
	body .no-data-available-image:before {height: 60px;background-size: 50px;width: 60px;}
	body .container.modal-popup-dt {padding-right: 0;padding-left: 0;}
	body .modal-dialog .btn.btn-primary.setpos {margin: 8px 4px 0 4px;}
	body .right-select-row{justify-content: left;flex: 0 0 100%;max-width:100%;padding-right: 0;}
	.profile-form .col.col-sm-4.col-md-4 {flex: 0 0 100%;max-width: 100%;}
	.profile-form .full-width-input .col.col-sm-4.col-md-4.pr-0 {flex: 0 0 100%; max-width: 100%;}
	.profile-form .full-width-input-50 .col.col-sm-6.col-md-6 {flex: 0 0 100%;max-width: 100%;margin-bottom: 0;}
	.white-box .card-title {font-size: 23px;}
	.mysubscription .subscription-top-text h3{font-size: 21px;}
	.mysubscription .subscription-top-text .text-right.h3 b{font-size: 20px;}
	.mysubscription .subscription-top-text span.status.h3{font-size: 16px;}
	.mysubscription .subscription-top-text .text-success {font-size: 14px;}
	.mysubscription .subscription-top-text .right-box{ text-align: right;} 
	.mysubscription .subscription-top-text .right-box .price-bold.h3{ text-align: right;font-size:16px;width: 100%;} 
	.mysubscription .subscription-top-text {flex-wrap: wrap;}
	.mysubscription .subscription-top-text {flex-wrap: wrap;margin-top: 0 !important;padding-top: 14px !important;}
	body .mysubscription .btn-primary {font-size: 14px;}
	.mysubscription-pages .payment-cn-text input {font-size: 16px;}
	.mysubscription-pages .payment-exp {font-size: 15px;}
	.add-family form .row{display: block;}
	.add-mb-wd-50 .col-md-4 {flex: 0 0 100%;max-width: 100%;margin-bottom: 1em;}
	.add-mb-wd-50 .col-md-8 {flex: 0 0 100%; max-width: 100%;}
	.col.add-full-wd-80 {flex: 0 0 100%;max-width:100%;}
	.col.add-full-wd-40 {flex: 0 0 100%;max-width:100%;}
	body .add-family .form-group {margin-bottom: 1rem;}
    body .add-family .custom-control.custom-checkbox.font-20.mt-7{margin-top: 0rem !important;}
	body .add-investment .col-md-4{    flex: 0 0 100%;max-width:100%;}
	body .add-investment .col-md-6{    flex: 0 0 100%;max-width:100%;}
	body .add-investment .currency-dropdown { width: 100% !important;margin-top: 0;}
	body .add-investment .col-md-6 .form-control.d-inline.ml-3.w-50{width: 100% !important;margin-left: 0rem !important;margin-top: 1.5em;}
	body .add-investment .form-group {margin-bottom: 1.5em;}
	body .row.nominees-row-full{display:block;margin-top: 4.5em;}
	body .nominees-row-full .row{display:block;}
	body .nominees-row {display: block;}
   .row.row.headingbottomborder.nominee-text{display: flex;margin-top:25px;}
   body .add-investment .card-title.card-title2{line-height: 22px;}
   body  .reactautocomplete div:first-child{width:100%;}
   body .hr-line {display: flex;margin-bottom: 2rem;}
   body .link-row .form-group.float-left.ml-4{    margin-left:0rem !important;}
   .row.row.upload-box{display: block;}
   .upload-row .dropzone {width: 100%;}
   .text-danger.suppproted-format {right: 0%;margin-left: 0rem !important;font-size: 13px;}
   .row.sharing-row-full{display: block;}
   .sharing-row-full .row{display: block;}
   .sharing-row-full .nominee-text .col-md-4{flex-basis: 0;flex-grow: 1;}
   body .btn-secondary::before{width: 14px;height:14px ;left:0px;top: 11px;background-size: 14px;}
   .inner-row.mb-pd-w0 .card-body.p-6{padding: 0rem !important;}
    .inner-row.mb-pd-w0  .stripeform {margin-top: 15px;}
	body .modal-popup-dt .card-title{line-height: 26px;}
	
   


}


@media (max-width:540px) {
.col-login .card-title {font-size: 24px;}
body .roundedname{font-size: 13px;}
.mobile-full .col.col-6{flex: 0 0 100%;max-width: 100%;}
body .btn.btn-primary.float-right{margin-top: 1em;margin-bottom: 1em;float: left !important;}
body .nominee-text b {font-size: 14px;line-height: 18px; display: inline-block;}

}

@media (max-width:330px) {
body .nominee-text b {font-size:13px;}
}


.roundedstylehead {
  display:inline-block;
  font-size:1.2em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
      background-color: rgb(116 191 56) !important;
  vertical-align:middle;
  color:white;
}
.card-profile-img-text {
  display: inline-block;
    font-size:28px;
    width: 90px;
    height: 90px;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
	margin: 0 auto;
    vertical-align: middle;
    color: white;
    position: absolute;
    top: -61px;
    left:0;
	right:0;
	display: flex;
    justify-content: center;
    align-items: center;
    border-color: white;
	    background-color: rgb(116 191 56) !important;
}

.sc-bdVaJa.kyPChT .profile-box{left:0;}

.roundedname {
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  vertical-align:middle;
  color:white;
  }
.roundednamesharing {
  display:inline-block;
  font-size:0.84em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  vertical-align:middle;
  color:white;
  margin-left: 2px;
  }
.sharedbynameclass {position: relative;}
.sharedbynameclass .name {position:absolute;margin-top: 6px;margin-left: 6px;}
.profilepicturemove {position: absolute;
  top: 0;
  left: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;}
.profilepicturemove .btn-secondary::before{
    content: '';
    background:  url(./images/delete.png);
    width:15px;
    height:15px;
    display: block;
    background-size:15px;
    position: absolute;
    left: -21px;
    top:4px;
    z-index: 999;
  }
.staysignin .custom-control-label {margin-left: -38px;}
#states-autocomplete-investmentname {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
  border-radius: 10px;
    font-size: 0.9rem;
}
#states-autocomplete-investmentname::-webkit-input-placeholder { /* Edge */
  content:"Investment Title";
}

#states-autocomplete-investmentname:-ms-input-placeholder { /* Internet Explorer 10-11 */
  content:"Investment Title";
}

#states-autocomplete-investmentname::placeholder {
  content:"Investment Title";
}

#states-autocomplete-investmentname::-ms-expand {
  background-color: transparent;
  border: 0;
}

#states-autocomplete-investmentname:focus {
  color: #495057;
  background-color: #fff;
  border-color: #1991eb;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

#states-autocomplete-investmentname::-webkit-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

#states-autocomplete-investmentname::-moz-placeholder {
  color: #adb5bd;
  opacity: 1;
}

#states-autocomplete-investmentname:-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

#states-autocomplete-investmentname::-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

#states-autocomplete-investmentname::placeholder {
  color: #adb5bd;
  opacity: 1;
}

#states-autocomplete-investmentname:disabled,
#states-autocomplete-investmentname[readonly] {
  background-color: #f8f9fa;
  opacity: 1;
}
.investmenttitle .item {font-size: 1rem;}
/* .menu {position: absolute;background: white;z-index: 9999;width: 93%;} */
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {padding:1px !important;}
.MuiOutlinedInput-root {border-radius: 10px;}
.MuiFormLabel-root {line-height: 0 !important;font-size: 0.9rem;}
.MuiAutocomplete-root.border-error .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color: #ff0000 !important;}
.MuiAutocomplete-root.border-error .MuiOutlinedInput-notchedOutline {border-color: #ff0000 !important;}

.accountholdername .MuiOutlinedInput-root {border-radius: 10px;border: 0px solid rgba(0, 40, 100, 0.12);}
.accountholdername .MuiFormControl-marginNormal {margin-top: 1.56px;margin-bottom:0px;}
.accountholdername .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{padding: 7px 9px 11px 11px !important;}
.accountholdername .MuiInputBase-root { font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",    "Helvetica Neue", Arial, sans-serif !important; }
.accountholdername input::placeholder {font-size:14px;}

.investmentname .MuiOutlinedInput-root {border-radius: 10px;border: 0px solid rgba(0, 40, 100, 0.12);}
.investmentname .MuiFormControl-marginNormal {margin-top: 1.56px;margin-bottom:0px;}
.investmentname .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{padding: 7px 9px 11px 11px !important;}
.investmentname .MuiInputBase-root { font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",    "Helvetica Neue", Arial, sans-serif !important; }
.investmentname input::placeholder {font-size:14px;}
body .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {padding:1px !important;}
body .MuiOutlinedInput-root {border-radius: 10px;}
body .MuiFormLabel-root {line-height: 0 !important;font-size: 0.9rem;}
body .MuiAutocomplete-root.border-error .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color: #ff0000 !important;}
body .MuiAutocomplete-root.border-error .MuiOutlinedInput-notchedOutline {border-color: #ff0000 !important;}
.editorerror .jodit-container {border:1px solid red !important;}
.data-table-extensions > .data-table-extensions-filter { float: right !important;}
.rdt_TableHeader {display: none !important;}
.react-bootstrap-table-page-btns-ul .page-item {background: #e1e1e1;border-radius: 30px 30px;height: 36px;font-size: 22px;margin: 5px 5px;}
.react-bootstrap-table-page-btns-ul .page-item .page-link {padding: 5px 14px;color:black;}
.react-bootstrap-table-page-btns-ul .page-item.active {background: #1e0253;}
.react-bootstrap-table-page-btns-ul .page-item.active .page-link {color:white;}
.button-row .dropdown{font-size: 18px !important;}
.question-mark-icon {content: '';background:  url(./images/tooltip-icons.png);width:28px;height:28px;display: block; background-size:28px;position: absolute;
  top:-1px;z-index: 999;cursor:pointer;right:0px;}
.investmenttitletooltip {font-size: 1rem !important;max-width: 660px !important;background-color: rgb(0 0 0 / 85%) !important;-webkit-transition-property: none !important;-moz-transition-property: none !important;-o-transition-property: none !important;transition-property: none !important;}
.investment-nominee-tooltip{display: inline-block;position: inherit;}
.nomineetooltip {font-size: 1rem !important;max-width: 670px !important;background-color: rgb(0 0 0 / 85%) !important;-webkit-transition-property: none !important;-moz-transition-property: none !important;-o-transition-property: none !important;transition-property: none !important;}
.commenttooltip {font-size: 1rem !important;max-width: 620px !important;background-color: rgb(0 0 0 / 85%) !important;-webkit-transition-property: none !important;-moz-transition-property: none !important;-o-transition-property: none !important;transition-property: none !important;}
.accountnumbertooltip {font-size: 1rem !important;max-width: 500px !important;background-color: rgb(0 0 0 / 85%) !important;-webkit-transition-property: none !important;-moz-transition-property: none !important;-o-transition-property: none !important;transition-property: none !important;}
.percentageofficon {display: inline;position: absolute;right: 8px;top: 37px;color: #b3bbc2;}

.width-10 {width: 10%;}
.width-20 {width: 20%;}
.width-25 {width: 25%;}
.width-30 {width: 30%;}
.width-40 {width: 40%;}
.width-50 {width: 50%;}
.width-60 {width: 60%;}
.width-70 {width: 70%;}
.width-80 {width: 80%;}
.width-90 {width: 90%;}
.templaterows {word-wrap: break-word;}
.investment-table th span {display: inline-block;}
.investment-table th {-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.filter-label input {font-size: 0.7rem;}
.modal-title.h4 {font-size: 42px;color: #1e0253;line-height: 55px;font-weight: 600;text-transform: uppercase;text-align: left;width: 75%;display: inline !important;}
.ModalCustomStyle .modal-header {margin: 35px 30px 0 45px;}
.popupprofilebody {min-height: 350px !important;}
.popupprofilebody img {max-height: 341px;max-width: 100%;display: block;width: 341px;margin: 0 auto;}
.modal-header .close:hover, .modal-header .close:focus {outline: none;}


.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
}

.Checkout .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.Checkout .StripeElement.IdealBankElement,
.Checkout .StripeElement.PaymentRequestButton {
  padding: 0;
}
input.InputElement.is-complete.Input{
  color:#1bba1b !important;
}
.Checkout.innerscheckout {
  max-width: 100%;
}
.no-data-available-text{color: #1e0253 !important;font-size: 20px !important;text-transform: capitalize !important;justify-content: center !important;}
.header-brand:hover {opacity:1 !important;}